"use strict";

// MODULE TARTE AU CITRON - CONFIGURATION Blarwitch
// //------------------------------------------
// jQuery(function ($) {
/* INITIALISATION */
tarteaucitron.init({
  privacyUrl: "/mentions-legales",
  /* Privacy policy url */
  hashtag: "#tarteaucitron",
  /* Open the panel with this hashtag */
  cookieName: "tarteaucitron",
  /* Cookie name */
  orientation: "bottom",
  /* Banner position (top - bottom) */
  showAlertSmall: false,
  /* Show the small banner on bottom right */
  cookieslist: false,
  /* Show the cookie list */
  closePopup: false,
  /* Show a close X on the banner */
  showIcon: false,
  /* Show cookie icon to manage cookies */
  iconPosition: "BottomRight",
  /* BottomRight, BottomLeft, TopRight and TopLeft */
  adblocker: true,
  /* Show a Warning if an adblocker is detected */
  DenyAllCta: true,
  /* Show the deny all button */
  AcceptAllCta: true,
  /* Show the accept all button when highPrivacy on */
  highPrivacy: true,
  /* HIGHLY RECOMMANDED Disable auto consent */
  handleBrowserDNTRequest: false,
  /* If Do Not Track == 1, disallow all */
  removeCredit: true,
  /* Remove credit link */
  moreInfoLink: true,
  /* Show more info link */
  useExternalCss: false,
  /* If false, the tarteaucitron.css file will be loaded */
  useExternalJs: false,
  /* If false, the tarteaucitron.js file will be loaded */
  //"cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for multisite */
  readmoreLink: "",
  /* Change the default readmore link */
  mandatory: true,
  /* Show a message about mandatory cookies */
}); // let lang = document.documentElement.lang
// if (lang == "fr-FR") {
//   let tarteaucitronForceLanguage = 'fr'
// }
// if (lang == "en-US") {
//   let tarteaucitronForceLanguage = 'en'
// }
// alert(tarteaucitronForceLanguage);

let currentLanguage = document.documentElement.lang;
let currentLanguageTarteaucitron = currentLanguage.substring(0, 2);
window.tarteaucitronForceLanguage = currentLanguageTarteaucitron;
/* GOOGLE ANALYTICS */

tarteaucitron.user.gtagUa = "G-WB7MXNLXDF"; // tarteaucitron.user.gtagCrossdomain = ['example.com', 'example2.com'];

tarteaucitron.user.gtagMore = function () {
  /* add here your optionnal gtag() */
};

(tarteaucitron.job = tarteaucitron.job || []).push("gtag");
